import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useForm, Controller } from 'react-hook-form';
import {
  Grid,
  Box,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  FormLabel,
} from '@mui/material';
import { Button, Typography } from '@northwell-health/nw-component-lib';
import InformationPage from './information';
import ResultsPage from './results';

const progressLeft = document.getElementsByClassName('progress-indicator-left');
const progressRight = document.getElementsByClassName(
  'progress-indicator-right',
);
const pageTitle = 'Patient health questionnaire form';

export default function MentalHealthScreener() {
  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    document.querySelector('title').textContent = pageTitle;
    document.getElementById('pageTextHeader').innerHTML = pageTitle;
    progressLeft[0].style.width = '25%';
    progressRight[0].style.width = '75%';
  }, []);

  const [informationPageShow, showInfoPage] = useState(true);
  const [PHQPageShow, showPHQPage] = useState(false);
  const [resultsPageShow, showResultsPage] = useState(false);
  const [phqScore] = useState([
    {
      result: [],
    },
  ]);
  const startPHQ = function () {
    showInfoPage(false);
    showPHQPage(true);
    showResultsPage(false);
  };

  const PHQResults = function (data) {
    showInfoPage(false);
    showPHQPage(false);
    showResultsPage(true);
    phqScore.result = data;
  };

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      questionOne: '',
      questionTwo: '',
    },
  });

  const surveyQuestions = {
    questions: [
      'Little interest or pleasure in doing things:',
      'Feeling down, depressed or hopeless:',
    ],
    answerOne: {
      textValue: 'Not at all',
      numValue: 0,
    },
    answerTwo: {
      textValue: 'Several days ',
      numValue: 1,
    },
    answerThree: {
      textValue: 'More than half the days',
      numValue: 2,
    },
    answerFour: {
      textValue: 'Nearly everyday',
      numValue: 3,
    },
  };

  const onSubmit = (data) => PHQResults(data);

  return (
    <div>
      {informationPageShow ? (
        <InformationPage onFormStateChange={startPHQ} />
      ) : null}
      {resultsPageShow ? <ResultsPage results={phqScore} /> : null}
      {PHQPageShow ? (
        <form name="PHQFORM">
          <Box className="pageContainer">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  type="h1"
                  gutterBottom={true}
                  style={{ marginTop: `${isMobile ? '40px' : '80px'}` }}
                >
                  Over the last 2 weeks, how often have you been bothered by the
                  following problems?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'block',
                }}
              >
                <Typography
                  type="body"
                  size="small"
                  weight="bold"
                  style={{ color: 'red' }}
                >
                  {'* '}
                </Typography>
                <Typography type="body" size="small" weight="bold">
                  Required
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 18 }}>
                <FormControl component="fieldset">
                  <FormLabel
                    style={{ fontSize: 18, marginBottom: 12 }}
                    component="legend"
                  >
                    {surveyQuestions.questions[0]}
                  </FormLabel>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="questionOne"
                    render={({ field }) => {
                      // console.log(field)
                      return (
                        <RadioGroup row={isMobile ? false : true} {...field}>
                          <FormControlLabel
                            value={surveyQuestions.answerOne.numValue}
                            control={<Radio />}
                            label={surveyQuestions.answerOne.textValue}
                          />
                          <FormControlLabel
                            value={surveyQuestions.answerTwo.numValue}
                            control={<Radio />}
                            label={surveyQuestions.answerTwo.textValue}
                          />
                          <FormControlLabel
                            value={surveyQuestions.answerThree.numValue}
                            control={<Radio />}
                            label={surveyQuestions.answerThree.textValue}
                          />
                          <FormControlLabel
                            value={surveyQuestions.answerFour.numValue}
                            control={<Radio />}
                            label={surveyQuestions.answerFour.textValue}
                          />
                        </RadioGroup>
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} style={{ marginBottom: 18 }}>
                <FormControl component="fieldset">
                  <FormLabel
                    style={{ fontSize: 18, marginBottom: 12 }}
                    component="legend"
                  >
                    {surveyQuestions.questions[1]}
                  </FormLabel>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="questionTwo"
                    render={({ field }) => {
                      return (
                        <RadioGroup row={isMobile ? false : true} {...field}>
                          <FormControlLabel
                            value={surveyQuestions.answerOne.numValue}
                            control={<Radio />}
                            label={surveyQuestions.answerOne.textValue}
                          />
                          <FormControlLabel
                            value={surveyQuestions.answerTwo.numValue}
                            control={<Radio />}
                            label={surveyQuestions.answerTwo.textValue}
                          />
                          <FormControlLabel
                            value={surveyQuestions.answerThree.numValue}
                            control={<Radio />}
                            label={surveyQuestions.answerThree.textValue}
                          />
                          <FormControlLabel
                            value={surveyQuestions.answerFour.numValue}
                            control={<Radio />}
                            label={surveyQuestions.answerFour.textValue}
                          />
                        </RadioGroup>
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid container spacing={4} style={{ margin: 'inherit' }}>
                <Grid item>
                  <Button
                    type="button"
                    text="Submit"
                    size="large"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="button"
                    text="Reset"
                    size="large"
                    color="secondary"
                    onClick={() => reset()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      ) : null}
    </div>
  );
}
