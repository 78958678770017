/**
 * Helper method to format an inputted phone number.
 * @param {String} phoneNumber - String number to format
 * @returns {String}
 */
export const formatPhoneNumber = (phoneNumber) => {
  // Format the phone number
  const formattedPhone = `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}${phoneNumber.slice(6)}`;
  return formattedPhone;
};

/**
 * Helper method to format an inputted date of birth to YYYY-MM-DD format.
 * @param {String} dateToFormat - String date to format
 * @returns {String}
 */
export const formatDateOfBirth = (dateToFormat) => {
  // Split the input date string by '/'
  const [month, day, year] = dateToFormat.split('/');

  // Return the date in 'YYYY-MM-DD' format
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};
