import envConfig from '../utils/configEnv/config';

// General method to generate headers.
const buildRequestHeaders = (token = undefined) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  return token ? { Authorization: `Bearer ${token}`, ...headers } : headers;
};

// Verify the patient's demographics
export const verifyPatientDemographics = async (
  sourceData,
  dob,
  mobileNumber,
) => {
  const dpxURL = 'https://' + envConfig?.REACT_APP_DPXURL;
  const verificationEndpoint = '/iforms/phq2/verify';
  // Build url.
  const fullURL = `${dpxURL}${verificationEndpoint}`;
  // Build request data
  const requestData = {
    sourceData,
    dob,
    mobileNumber,
  };
  // Build request options.
  const requestOptions = {
    method: 'POST',
    headers: buildRequestHeaders(),
    body: JSON.stringify(requestData),
  };
  // Add visitor(s).
  const response = await fetch(fullURL, requestOptions);
  const responseData = await response.json();
  // Return data.
  return responseData?.response;
};
