import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Grid, Box } from '@mui/material';
import { Button, Typography } from '@northwell-health/nw-component-lib';

const pageTitle = 'Patient health questionnaire form';

const progressLeft = document.getElementsByClassName('progress-indicator-left');
const progressRight = document.getElementsByClassName(
  'progress-indicator-right',
);

const InformationPage = (props) => {
  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { onFormStateChange } = props;

  const nextPage = () => {
    onFormStateChange();
  };

  useEffect(() => {
    document.querySelector('title').textContent = pageTitle;
    document.getElementById('pageTextHeader').innerHTML = pageTitle;
    progressLeft[0].style.width = '50%';
    progressRight[0].style.width = '50%';
  }, []);

  return (
    <Box className="pageContainer">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="left"
      >
        <Grid item xs={12}>
          <Typography
            type="h1"
            gutterBottom={true}
            style={{
              marginTop: `${isMobile ? '40px' : '80px'}`,
              marginBottom: 24,
            }}
          >
            Overview
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: `${isMobile ? '' : '75%'}` }}>
          <Grid item xs={12} style={{ marginBottom: 24 }}>
            <Typography
              type="body"
              size={isMobile ? 'small' : 'large'}
              weight="light"
            >
              Northwell stresses the importance of depression screening for all
              patients annually. We ask these questions because it's
              important-and because we can help.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 24 }}>
            <Typography
              type="body"
              size={isMobile ? 'small' : 'large'}
              weight="light"
            >
              Please be advised that your provider won't be reviewing your
              answers from this questionnaire before or during your appointment,
              but you will receive information and resources if needed upon
              completion of this survey. If you need immediate care, please go
              to your nearest emergency room or call 911.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <Button
            text="Continue"
            color="primary"
            size="large"
            type="submit"
            onClick={nextPage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default InformationPage;
