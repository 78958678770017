import { Toast } from '@northwell-health/nw-component-lib';

const ToastAlert = (props) => {
  const {
    open,
    message,
    type = 'success',
    vertical = 'top',
    horizontal = 'right',
  } = props;
  return (
    <Toast
      open={open}
      handleClose={() => null}
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      autoHideDuration={5000}
      text={message}
      type={type}
      dismissible={false}
      positionOffset={10}
    />
  );
};

export default ToastAlert;
